/* Default inactive pill */
.swiper-pagination-bullet {
  width: 24px; /* Default shorter width */
  height: 6px;
  background-color: #b9b9b9;
  border: 1px solid #b9b9b9;
  border-radius: 9999px;
  transition: all 0.3s ease;
  margin: 1px !important;
}

/* Active pill */
.swiper-pagination-bullet-active {
  width: 48px; /* Stretched width for active state */
  background-color: #494949;
}
