@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-swiper .slide {
  @apply opacity-30 transition-opacity duration-300;
}

.hero-swiper .swiper-slide-active {
  @apply opacity-100;
}

.top-list-swiper .swiper-wrapper {
  display: flex;
  align-items: center;
}

.landing-swiper {
  position: relative;
}

.landing-swiper-blur {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 5%;
  background: linear-gradient(
    to right,
    rgba(255, 247, 230, 1),
    rgba(255, 247, 230, 0)
  );
  pointer-events: none;
  z-index: 10;
}

.landing-swiper-blur.right-0 {
  right: 0;
  background: linear-gradient(
    to left,
    rgba(255, 247, 230, 1),
    rgba(255, 247, 230, 0)
  );
}

@keyframes drawPathReverse {
  0% {
    stroke-dasharray: 1000;
    stroke-dashoffset: -1000;
  }
  50% {
    stroke-dasharray: 1000, 1000;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 1000, 1000;
    stroke-dashoffset: 1000;
  }
}

.animated-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: drawPathReverse 2s linear infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: -1s; /* Add a negative delay to remove the pause between animations */
}

@keyframes drawTriangle {
  0% {
    stroke-dasharray: 1000;
    stroke-dashoffset: -1000;
  }
  50% {
    stroke-dasharray: 1000, 1000;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 1000, 1000;
    stroke-dashoffset: 1000;
  }
}

/* Apply both animations */
.animated-triangle {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: drawTriangle 2s linear infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: -1s;
}

@keyframes drawTriangle {
  0% {
    stroke-dasharray: 1000;
    stroke-dashoffset: -1000;
  }
  50% {
    stroke-dasharray: 1000, 1000;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 1000, 1000;
    stroke-dashoffset: 1000;
  }
}
